import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function AllMembers() {
    const [dropdown, setDropdown] = useState(false)

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header row gutters-5">
                            <div className="col text-center text-md-left">
                                <h5 className="mb-md-0 h6">All members</h5>
                            </div>
                            <div className="col-md-3">
                                <form className id="sort_members" action method="GET">
                                    <div className="input-group input-group-sm">
                                        <input type="text" className="form-control" id="search" name="search" placeholder="Type first name / last name / ID & Enter" fdprocessedid="hh7lxk" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card-body">
                            <table className="table aiz-table mb-0 footable footable-1 breakpoint-xl" style={{}}>
                                <thead>
                                    <tr className="footable-header">
                                        <th className="footable-first-visible" style={{ display: 'table-cell' }}>#</th><th style={{ display: 'table-cell' }}>Image</th><th style={{ display: 'table-cell' }}>Member Code</th><th data-breakpoints="md" style={{ display: 'table-cell' }}>Name</th><th data-breakpoints="md" style={{ display: 'table-cell' }}>Gender</th><th data-breakpoints="md" style={{ display: 'table-cell' }}>Approval Status</th><th data-breakpoints="md" style={{ display: 'table-cell' }}>Profile Reported</th><th data-breakpoints="md" style={{ display: 'table-cell' }}>Member Science</th><th data-breakpoints="md" style={{ display: 'table-cell' }}>Member Status</th><th className="text-right footable-last-visible" style={{ display: 'table-cell' }}>Options</th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="footable-first-visible" style={{ display: 'table-cell' }}>1</td>
                                        <td style={{ display: 'table-cell' }}>
                                            <img className="img-md" src="https://weddemoadmin.abaris.in/public/assets/img/avatar-place.png" height="45px" alt="photo" />
                                        </td>
                                        <td style={{ display: 'table-cell' }}>20230248</td>
                                        <td style={{ display: 'table-cell' }}>Jagan Eakambaram</td>
                                        <td style={{ display: 'table-cell' }}>Male</td>
                                        <td style={{ display: 'table-cell' }}>
                                            <span className="badge badge-inline badge-success">Approved</span>
                                        </td>
                                        <td style={{ display: 'table-cell' }}>
                                            0
                                        </td>
                                        <td style={{ display: 'table-cell' }}>22-02-2023</td>
                                        <td style={{ display: 'table-cell' }}>
                                            <span className="badge badge-inline badge-success">Active</span>
                                        </td>
                                        <td className="text-right footable-last-visible" style={{ display: 'table-cell' }}>
                                            <div className="btn-group mb-2">
                                                <div className="btn-group">



                                                    <button type="button" className="btn py-0" data-toggle="dropdown" aria-expanded="false" onClick={() => { setDropdown(!dropdown) }}>
                                                        <i className="las la-ellipsis-v" />
                                                    </button>
                                                    <div className={`dropdown-menu dropdown-menu-right ${dropdown ? "mm-show" : "extra"}`}>
                                                        <Link to="/admin/members/members-detail" className="dropdown-item">View</Link>

                                                        <Link className="dropdown-item" to="/admin/members/members-edit">Edit</Link>

                                                        <Link to="#" className="dropdown-item">Block</Link>

                                                        <a className="dropdown-item" onclick="package_info(48)" href="javascript:void(0);">Package</a>
                                                        <a className="dropdown-item" onclick="wallet_balance_update(48,0)" href="javascript:void(0);">Wallet Balance</a>
                                                        <a href="https://weddemoadmin.abaris.in/admin/members/login/eyJpdiI6InpOcUc4SWRoZUp5MGNZYVROcXBVMGc9PSIsInZhbHVlIjoid2MyNk9KSFV3WnkrSHlEMEhYT0dpQT09IiwibWFjIjoiMWZiODZhNDZkZDBmZDYzM2EyMTRlZTZhOWYyOGY0YjZiZTcyMzJkYTk0MDliNDc3MzZiODc3NzdkMzVjMDE0MCIsInRhZyI6IiJ9" className="dropdown-item">Log in as this Member</a>
                                                        <a className="dropdown-item confirm-delete" data-href="https://weddemoadmin.abaris.in/admin/members/destroy/48">Delete</a>

                                                    </div>

                                                    

                                                </div>
                                            </div>
                                        </td></tr><tr>
                                        <td className="footable-first-visible" style={{ display: 'table-cell' }}>2</td><td style={{ display: 'table-cell' }}>
                                            <img className="img-md" src="https://weddemoadmin.abaris.in/public/assets/img/avatar-place.png" height="45px" alt="photo" />
                                        </td><td style={{ display: 'table-cell' }}>20210647</td><td style={{ display: 'table-cell' }}>Cairo Carney</td><td style={{ display: 'table-cell' }}>Female</td><td style={{ display: 'table-cell' }}>
                                            <span className="badge badge-inline badge-pending">Pending</span>
                                        </td><td style={{ display: 'table-cell' }}>
                                            0
                                        </td><td style={{ display: 'table-cell' }}>29-06-2021</td><td style={{ display: 'table-cell' }}>
                                            <span className="badge badge-inline badge-success">Active</span>
                                        </td><td className="text-right footable-last-visible" style={{ display: 'table-cell' }}>
                                            <div className="btn-group mb-2">
                                                <div className="btn-group">
                                                    <button type="button" className="btn py-0" data-toggle="dropdown" aria-expanded="false">
                                                        <i className="las la-ellipsis-v" />
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <Link to="#" className="dropdown-item">View</Link>

                                                        <Link className="dropdown-item" to="#">Edit</Link>

                                                        <a href="javascript:void(0);" className="dropdown-item" onclick="block_member(47)">Block</a>

                                                        <a className="dropdown-item" onclick="approve_member(47)" href="javascript:void(0);">Approve</a>
                                                        <a className="dropdown-item" onclick="package_info(47)" href="javascript:void(0);">Package</a>
                                                        <a className="dropdown-item" onclick="wallet_balance_update(47,0)" href="javascript:void(0);">Wallet Balance</a>
                                                        <a href="https://weddemoadmin.abaris.in/admin/members/login/eyJpdiI6IkhzYloxT2laS05nVS9uc3Y3eFlJSFE9PSIsInZhbHVlIjoiUjN3U1NLZktYQXBWMnpiT2YwbERyUT09IiwibWFjIjoiZjRlODJmNjg1NWZkYTQ2OGViYTc1ZTUzNDEzYjJiODJiNDViMDdlNWYyYjExMjA3YjYyNDAzOGZkMWNkZDQzYSIsInRhZyI6IiJ9" className="dropdown-item">Log in as this Member</a>
                                                        <a className="dropdown-item confirm-delete" data-href="https://weddemoadmin.abaris.in/admin/members/destroy/47">Delete</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </td></tr><tr>
                                        <td className="footable-first-visible" style={{ display: 'table-cell' }}>3</td><td style={{ display: 'table-cell' }}>
                                            <img className="img-md" src="https://weddemoadmin.abaris.in/public/uploads/all/JBNnlxl0aX4iRk2e3qCpjDca3WFBArAi4tSPJjeC.jpg" height="45px" alt="photo" />
                                        </td><td style={{ display: 'table-cell' }}>A5F900A6</td><td style={{ display: 'table-cell' }}>Wilson Arnold</td><td style={{ display: 'table-cell' }}>Male</td><td style={{ display: 'table-cell' }}>
                                            <span className="badge badge-inline badge-danger">Blocked</span>
                                        </td><td style={{ display: 'table-cell' }}>
                                            0
                                        </td><td style={{ display: 'table-cell' }}>07-04-2021</td><td style={{ display: 'table-cell' }}>
                                            <span className="badge badge-inline badge-success">Active</span>
                                        </td><td className="text-right footable-last-visible" style={{ display: 'table-cell' }}>
                                            <div className="btn-group mb-2">
                                                <div className="btn-group">
                                                    <button type="button" className="btn py-0" data-toggle="dropdown" aria-expanded="false">
                                                        <i className="las la-ellipsis-v" />
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a className="dropdown-item" href="https://weddemoadmin.abaris.in/admin/members/36">View</a>
                                                        <a className="dropdown-item" href="https://weddemoadmin.abaris.in/admin/members/eyJpdiI6IkoxNmtCL0Y1dE5CR1B3WDZ4aU12REE9PSIsInZhbHVlIjoiblZVVjVlN0h0SUZZQmIzbWt3dFpUdz09IiwibWFjIjoiNWYwYTA4ZWUyOGZlOGE5MjY3MzNkNDZjNDQzMjQ1YWU3NzlkNWYzNzljYzY5OWZlMjNiZjViNWM1NWJhZmNjMiIsInRhZyI6IiJ9/edit">Edit</a>
                                                        <a className="dropdown-item" onclick="unblock_member(36)" href="javascript:void(0);">Unblock</a>
                                                        <a className="dropdown-item" onclick="package_info(36)" href="javascript:void(0);">Package</a>
                                                        <a className="dropdown-item" onclick="wallet_balance_update(36,0)" href="javascript:void(0);">Wallet Balance</a>
                                                        <a href="https://weddemoadmin.abaris.in/admin/members/login/eyJpdiI6InBCUE9rZjUxbEJ2bysyVWUzb1ViNHc9PSIsInZhbHVlIjoiYzNzaEtVRXFpeEljSHJqTmhsN3AwUT09IiwibWFjIjoiNjU4MWU5OTQyNjNjODEyODU1NDQ3YjljOGNhN2I0ZDM2ZjljOTczNTJjNGU1ZjNmZmE0OTRiNjMyMjRkZDQ1MSIsInRhZyI6IiJ9" className="dropdown-item">Log in as this Member</a>
                                                        <a className="dropdown-item confirm-delete" data-href="https://weddemoadmin.abaris.in/admin/members/destroy/36">Delete</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </td></tr></tbody>
                            </table>
                            <div className="aiz-pagination">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllMembers