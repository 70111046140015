import React from 'react'

function FooterComp() {
    return (
        <>
            <div className=" text-center py-3 px-15px px-lg-25px mt-auto mt-auto-2">
                <p class="mb-0">© testing v3.6</p>
            </div>
        </>
    )
}

export default FooterComp;