import React from 'react'
import Dashboard from '../../Components/dashboard/Dashboard'

function DashboardPage() {
    return (
        <>
            <Dashboard />
        </>
    )
}
export default DashboardPage